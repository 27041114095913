import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/popper.js.org/popper.js.org/src/components/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "popperjs-v1x",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#popperjs-v1x",
        "aria-label": "popperjs v1x permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Popper.js (v1.x)`}</h1>
    <p>{`This page covers the API reference and documentation of Popper 1 (`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`popper.js`}</code>{` on
npm).`}</p>
    <p>{`This page is here to help consumers who haven't migrated yet to the most recent
version, for the latest version documentation `}<a parentName="p" {...{
        "href": "/docs/"
      }}>{`click here`}</a>{`.`}</p>
    <x-ad />
    <h2 {...{
      "id": "classes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#classes",
        "aria-label": "classes permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Classes`}</h2>
    <dl>
  <dt>
    <a href="#Popper">Popper</a>
  </dt>
  <dd></dd>
    </dl>
    <h2 {...{
      "id": "members",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#members",
        "aria-label": "members permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Members`}</h2>
    <dl>
  <dt>
    <a href="#dataObject">dataObject</a>
  </dt>
  <dd>
    <p>
      The <code>dataObject</code> is an object containing all the information
      used by Popper.js. This object is passed to modifiers and to the{' '}
      <code>onCreate</code> and <code>onUpdate</code> callbacks.
    </p>
  </dd>
  <dt>
    <a href="#referenceObject">referenceObject</a>
  </dt>
  <dd>
    <p>
      The <code>referenceObject</code> is an object that provides an interface
      compatible with Popper.js and lets you use it as replacement of a real DOM
      node.
      <br />
      You can use this method to position a popper relatively to a set of
      coordinates in case you don&#39;t have a DOM node to use as reference.
    </p>
    <pre>
      <code>new Popper(referenceObject, popperNode);</code>
    </pre>
    <p>NB: This feature isn&#39;t supported in Internet Explorer 10.</p>
  </dd>
    </dl>
    <h2 {...{
      "id": "objects",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#objects",
        "aria-label": "objects permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Objects`}</h2>
    <dl>
  <dt>
    <a href="#modifiers">modifiers</a> : <code>object</code>
  </dt>
  <dd>
    <p>
      Modifiers are plugins used to alter the behavior of your poppers.
      <br />
      Popper.js uses a set of 9 modifiers to provide all the basic
      functionalities needed by the library.
    </p>
    <p>
      Usually you don&#39;t want to override the <code>order</code>,{' '}
      <code>fn</code> and <code>onLoad</code> props. All the other properties
      are configurations that could be tweaked.
    </p>
  </dd>
    </dl>
    <h2 {...{
      "id": "functions",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#functions",
        "aria-label": "functions permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Functions`}</h2>
    <dl>
  <dt>
    <a href="#getWindow">getWindow(element)</a> ⇒ <code>Window</code>
  </dt>
  <dd>
    <p>Get the window associated with the element</p>
  </dd>
  <dt>
    <a href="#ModifierFn">ModifierFn(data, options)</a> ⇒{' '}
    <code>
      <a href="#dataObject">dataObject</a>
    </code>
  </dt>
  <dd>
    <p>
      Modifier function, each modifier can have a function of this type assigned
      to its <code>fn</code> property.
      <br />
      These functions will be called on each update, this means that you must
      make sure they are performant enough to avoid performance bottlenecks.
    </p>
  </dd>
    </dl>
    <h2 {...{
      "id": "typedefs",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#typedefs",
        "aria-label": "typedefs permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Typedefs`}</h2>
    <dl>
  <dt>
    <a href="#onUpdate">onUpdate</a> : <code>function</code>
  </dt>
  <dd></dd>
  <dt>
    <a href="#onCreate">onCreate</a> : <code>function</code>
  </dt>
  <dd></dd>
    </dl>
    <a name="Popper"></a>
    <h2 {...{
      "id": "popper",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#popper",
        "aria-label": "popper permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Popper`}</h2>
    <p><strong parentName="p">{`Kind`}</strong>{`: global class`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#Popper"
        }}>{`Popper`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#new_Popper_new"
            }}>{`new Popper(reference, popper, options)`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#Popper.Defaults"
            }}>{`.Defaults`}</a>{` : `}<code>{`Object`}</code>
            <ul parentName="li">
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#Popper.Defaults.placement"
                }}>{`.placement`}</a></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#Popper.Defaults.positionFixed"
                }}>{`.positionFixed`}</a></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#Popper.Defaults.eventsEnabled"
                }}>{`.eventsEnabled`}</a></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#Popper.Defaults.removeOnDestroy"
                }}>{`.removeOnDestroy`}</a></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#Popper.Defaults.modifiers"
                }}>{`.modifiers`}</a></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#Popper.Defaults.onCreate"
                }}>{`.onCreate()`}</a></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#Popper.Defaults.onUpdate"
                }}>{`.onUpdate()`}</a></li>
            </ul>
          </li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#Popper.placements"
            }}>{`.placements`}</a>{` : `}<code>{`enum`}</code></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#Popper.update"
            }}>{`.update()`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#Popper.destroy"
            }}>{`.destroy()`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#Popper.enableEventListeners"
            }}>{`.enableEventListeners()`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#Popper.disableEventListeners"
            }}>{`.disableEventListeners()`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#Popper.scheduleUpdate"
            }}>{`.scheduleUpdate()`}</a></li>
        </ul>
      </li>
    </ul>
    <a name="new_Popper_new"></a>
    <h3 {...{
      "id": "new-popperreference-popper-options",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#new-popperreference-popper-options",
        "aria-label": "new popperreference popper options permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`new Popper(reference, popper, options)`}</h3>
    <p>{`Creates a new Popper.js instance.`}</p>
    <p><strong parentName="p">{`Returns`}</strong>{`: `}<code>{`Object`}</code>{` - instance - The generated Popper.js instance`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Param`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`reference`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><code>{`Element`}</code>{` `}{`|`}{` `}<a parentName="td" {...{
              "href": "#referenceObject"
            }}><code>{`referenceObject`}</code></a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The reference element used to position the popper`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`popper`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><code>{`Element`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The HTML / XML element used as the popper`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`options`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><code>{`Object`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Your custom options to override the ones defined in `}<a parentName="td" {...{
              "href": "#defaults"
            }}>{`Defaults`}</a></td>
        </tr>
      </tbody>
    </table>
    <a name="Popper.Defaults"></a>
    <h3 {...{
      "id": "popperdefaults--codeobjectcode",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#popperdefaults--codeobjectcode",
        "aria-label": "popperdefaults  codeobjectcode permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Popper.Defaults : `}<code>{`Object`}</code></h3>
    <p>{`Default options provided to Popper.js constructor.`}<br />{` These can be overridden
using the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`options`}</code>{` argument of Popper.js.`}<br />{` To override an option, simply
pass an object with the same structure of the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`options`}</code>{` object, as the 3rd
argument. For example:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`new`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`Popper`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`ref`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` pop`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  modifiers`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    preventOverflow`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` enabled`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`false`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <p><strong parentName="p">{`Kind`}</strong>{`: static property of `}<a parentName="p" {...{
        "href": "#Popper"
      }}><code>{`Popper`}</code></a></p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#Popper.Defaults"
        }}>{`.Defaults`}</a>{` : `}<code>{`Object`}</code>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#Popper.Defaults.placement"
            }}>{`.placement`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#Popper.Defaults.positionFixed"
            }}>{`.positionFixed`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#Popper.Defaults.eventsEnabled"
            }}>{`.eventsEnabled`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#Popper.Defaults.removeOnDestroy"
            }}>{`.removeOnDestroy`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#Popper.Defaults.modifiers"
            }}>{`.modifiers`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#Popper.Defaults.onCreate"
            }}>{`.onCreate()`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#Popper.Defaults.onUpdate"
            }}>{`.onUpdate()`}</a></li>
        </ul>
      </li>
    </ul>
    <a name="Popper.Defaults.placement"></a>
    <h4 {...{
      "id": "defaultsplacement",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#defaultsplacement",
        "aria-label": "defaultsplacement permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Defaults.placement`}</h4>
    <p>{`Popper's placement.`}</p>
    <p><strong parentName="p">{`Kind`}</strong>{`: static property of `}<a parentName="p" {...{
        "href": "#Popper.Defaults"
      }}><code>{`Defaults`}</code></a><br parentName="p"></br>{`
`}<strong parentName="p">{`Properties`}</strong></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`placement`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#Popper.placements"
            }}><code>{`placements`}</code></a></td>
          <td parentName="tr" {...{
            "align": null
          }}><code>{`'`}{`bottom`}{`'`}</code></td>
        </tr>
      </tbody>
    </table>
    <a name="Popper.Defaults.positionFixed"></a>
    <h4 {...{
      "id": "defaultspositionfixed",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#defaultspositionfixed",
        "aria-label": "defaultspositionfixed permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Defaults.positionFixed`}</h4>
    <p>{`Set this to true if you want popper to position it self in 'fixed' mode`}</p>
    <p><strong parentName="p">{`Kind`}</strong>{`: static property of `}<a parentName="p" {...{
        "href": "#Popper.Defaults"
      }}><code>{`Defaults`}</code></a><br parentName="p"></br>{`
`}<strong parentName="p">{`Properties`}</strong></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`positionFixed`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><code>{`Boolean`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}><code>{`false`}</code></td>
        </tr>
      </tbody>
    </table>
    <a name="Popper.Defaults.eventsEnabled"></a>
    <h4 {...{
      "id": "defaultseventsenabled",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#defaultseventsenabled",
        "aria-label": "defaultseventsenabled permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Defaults.eventsEnabled`}</h4>
    <p>{`Whether events (resize, scroll) are initially enabled.`}</p>
    <p><strong parentName="p">{`Kind`}</strong>{`: static property of `}<a parentName="p" {...{
        "href": "#Popper.Defaults"
      }}><code>{`Defaults`}</code></a><br parentName="p"></br>{`
`}<strong parentName="p">{`Properties`}</strong></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`eventsEnabled`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><code>{`Boolean`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}><code>{`true`}</code></td>
        </tr>
      </tbody>
    </table>
    <a name="Popper.Defaults.removeOnDestroy"></a>
    <h4 {...{
      "id": "defaultsremoveondestroy",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#defaultsremoveondestroy",
        "aria-label": "defaultsremoveondestroy permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Defaults.removeOnDestroy`}</h4>
    <p>{`Set to true if you want to automatically remove the popper when you call the
`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`destroy`}</code>{` method.`}</p>
    <p><strong parentName="p">{`Kind`}</strong>{`: static property of `}<a parentName="p" {...{
        "href": "#Popper.Defaults"
      }}><code>{`Defaults`}</code></a><br parentName="p"></br>{`
`}<strong parentName="p">{`Properties`}</strong></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`removeOnDestroy`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><code>{`Boolean`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}><code>{`false`}</code></td>
        </tr>
      </tbody>
    </table>
    <a name="Popper.Defaults.modifiers"></a>
    <h4 {...{
      "id": "defaultsmodifiers",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#defaultsmodifiers",
        "aria-label": "defaultsmodifiers permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Defaults.modifiers`}</h4>
    <p>{`List of modifiers used to modify the offsets before they are applied to the
popper. They provide most of the functionalities of Popper.js.`}</p>
    <p><strong parentName="p">{`Kind`}</strong>{`: static property of `}<a parentName="p" {...{
        "href": "#Popper.Defaults"
      }}><code>{`Defaults`}</code></a><br parentName="p"></br>{`
`}<strong parentName="p">{`Properties`}</strong></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#modifiers"
            }}><code>{`modifiers`}</code></a></td>
        </tr>
      </tbody>
    </table>
    <a name="Popper.Defaults.onCreate"></a>
    <h4 {...{
      "id": "defaultsoncreate",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#defaultsoncreate",
        "aria-label": "defaultsoncreate permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Defaults.onCreate()`}</h4>
    <p>{`Callback called when the popper is created.`}<br />{` By default, it is set to
no-op.`}<br />{` Access Popper.js instance with `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`data.instance`}</code>{`.`}</p>
    <p><strong parentName="p">{`Kind`}</strong>{`: static method of `}<a parentName="p" {...{
        "href": "#Popper.Defaults"
      }}><code>{`Defaults`}</code></a><br parentName="p"></br>{`
`}<strong parentName="p">{`Properties`}</strong></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#onCreate"
            }}><code>{`onCreate`}</code></a></td>
        </tr>
      </tbody>
    </table>
    <a name="Popper.Defaults.onUpdate"></a>
    <h4 {...{
      "id": "defaultsonupdate",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#defaultsonupdate",
        "aria-label": "defaultsonupdate permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Defaults.onUpdate()`}</h4>
    <p>{`Callback called when the popper is updated. This callback is not called on the
initialization/creation of the popper, but only on subsequent updates.`}<br />{` By
default, it is set to no-op.`}<br />{` Access Popper.js instance with
`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`data.instance`}</code>{`.`}</p>
    <p><strong parentName="p">{`Kind`}</strong>{`: static method of `}<a parentName="p" {...{
        "href": "#Popper.Defaults"
      }}><code>{`Defaults`}</code></a><br parentName="p"></br>{`
`}<strong parentName="p">{`Properties`}</strong></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#onUpdate"
            }}><code>{`onUpdate`}</code></a></td>
        </tr>
      </tbody>
    </table>
    <a name="Popper.placements"></a>
    <h3 {...{
      "id": "popperplacements--codeenumcode",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#popperplacements--codeenumcode",
        "aria-label": "popperplacements  codeenumcode permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Popper.placements : `}<code>{`enum`}</code></h3>
    <p>{`List of accepted placements to use as values of the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`placement`}</code>{` option.`}<br />{`
Valid placements are:`}</p>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`auto`}</code></li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`top`}</code></li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`right`}</code></li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`bottom`}</code></li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`left`}</code></li>
    </ul>
    <p>{`Each placement can have a variation from this list:`}</p>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`-start`}</code></li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`-end`}</code></li>
    </ul>
    <p>{`Variations are interpreted easily if you think of them as the left to right
written languages. Horizontally (`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`top`}</code>{` and `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`bottom`}</code>{`), `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`start`}</code>{` is left and `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`end`}</code>{`
is right.`}<br />{` Vertically (`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`left`}</code>{` and `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`right`}</code>{`), `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`start`}</code>{` is top and `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`end`}</code>{` is
bottom.`}</p>
    <p>{`Some valid examples are:`}</p>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`top-end`}</code>{` (on top of reference, right aligned)`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`right-start`}</code>{` (on right of reference, top aligned)`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`bottom`}</code>{` (on bottom, centered)`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`auto-end`}</code>{` (on the side with more space available, alignment depends by
placement)`}</li>
    </ul>
    <p><strong parentName="p">{`Kind`}</strong>{`: static enum of `}<a parentName="p" {...{
        "href": "#Popper"
      }}><code>{`Popper`}</code></a><br parentName="p"></br>{`
`}<strong parentName="p">{`Read only`}</strong>{`: true`}</p>
    <a name="Popper.update"></a>
    <h3 {...{
      "id": "popperupdate",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#popperupdate",
        "aria-label": "popperupdate permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Popper.update()`}</h3>
    <p>{`Updates the position of the popper, computing the new offsets and applying the
new style.`}<br />{` Prefer `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`scheduleUpdate`}</code>{` over `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`update`}</code>{` because of performance
reasons.`}</p>
    <p><strong parentName="p">{`Kind`}</strong>{`: static method of `}<a parentName="p" {...{
        "href": "#Popper"
      }}><code>{`Popper`}</code></a></p>
    <a name="Popper.destroy"></a>
    <h3 {...{
      "id": "popperdestroy",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#popperdestroy",
        "aria-label": "popperdestroy permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Popper.destroy()`}</h3>
    <p>{`Destroys the popper.`}</p>
    <p><strong parentName="p">{`Kind`}</strong>{`: static method of `}<a parentName="p" {...{
        "href": "#Popper"
      }}><code>{`Popper`}</code></a></p>
    <a name="Popper.enableEventListeners"></a>
    <h3 {...{
      "id": "popperenableeventlisteners",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#popperenableeventlisteners",
        "aria-label": "popperenableeventlisteners permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Popper.enableEventListeners()`}</h3>
    <p>{`It will add resize/scroll events and start recalculating position of the popper
element when they are triggered.`}</p>
    <p><strong parentName="p">{`Kind`}</strong>{`: static method of `}<a parentName="p" {...{
        "href": "#Popper"
      }}><code>{`Popper`}</code></a></p>
    <a name="Popper.disableEventListeners"></a>
    <h3 {...{
      "id": "popperdisableeventlisteners",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#popperdisableeventlisteners",
        "aria-label": "popperdisableeventlisteners permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Popper.disableEventListeners()`}</h3>
    <p>{`It will remove resize/scroll events and won't recalculate popper position when
they are triggered. It also won't trigger `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`onUpdate`}</code>{` callback anymore, unless
you call `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`update`}</code>{` method manually.`}</p>
    <p><strong parentName="p">{`Kind`}</strong>{`: static method of `}<a parentName="p" {...{
        "href": "#Popper"
      }}><code>{`Popper`}</code></a></p>
    <a name="Popper.scheduleUpdate"></a>
    <h3 {...{
      "id": "popperscheduleupdate",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#popperscheduleupdate",
        "aria-label": "popperscheduleupdate permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Popper.scheduleUpdate()`}</h3>
    <p>{`Schedules an update. It will run on the next UI update available.`}</p>
    <p><strong parentName="p">{`Kind`}</strong>{`: static method of `}<a parentName="p" {...{
        "href": "#Popper"
      }}><code>{`Popper`}</code></a></p>
    <a name="dataObject"></a>
    <h2 {...{
      "id": "dataobject",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#dataobject",
        "aria-label": "dataobject permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`dataObject`}</h2>
    <p>{`The `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`dataObject`}</code>{` is an object containing all the information used by Popper.js.
This object is passed to modifiers and to the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`onCreate`}</code>{` and `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`onUpdate`}</code>{`
callbacks.`}</p>
    <p><strong parentName="p">{`Kind`}</strong>{`: global variable`}<br parentName="p"></br>{`
`}<strong parentName="p">{`Properties`}</strong></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`data.instance`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><code>{`Object`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The Popper.js instance`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`data.placement`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><code>{`String`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Placement applied to popper`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`data.originalPlacement`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><code>{`String`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Placement originally defined on init`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`data.flipped`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><code>{`Boolean`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`True if popper has been flipped by flip modifier`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`data.hide`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><code>{`Boolean`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`True if the reference element is out of boundaries, useful to know when to hide the popper`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`data.arrowElement`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><code>{`HTMLElement`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Node used as arrow by arrow modifier`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`data.styles`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><code>{`Object`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Any CSS property defined here will be applied to the popper. It expects the JavaScript nomenclature (eg. `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`marginBottom`}</code>{`)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`data.arrowStyles`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><code>{`Object`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Any CSS property defined here will be applied to the popper arrow. It expects the JavaScript nomenclature (eg. `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`marginBottom`}</code>{`)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`data.boundaries`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><code>{`Object`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Offsets of the popper boundaries`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`data.offsets`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><code>{`Object`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The measurements of popper, reference and arrow elements`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`data.offsets.popper`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><code>{`Object`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`top`}</code>{`, `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`left`}</code>{`, `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`width`}</code>{`, `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`height`}</code>{` values`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`data.offsets.reference`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><code>{`Object`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`top`}</code>{`, `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`left`}</code>{`, `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`width`}</code>{`, `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`height`}</code>{` values`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`data.offsets.arrow`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><code>{`Object`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`top`}</code>{` and `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`left`}</code>{` offsets, only one of them will be different from 0`}</td>
        </tr>
      </tbody>
    </table>
    <a name="referenceObject"></a>
    <h2 {...{
      "id": "referenceobject",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#referenceobject",
        "aria-label": "referenceobject permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`referenceObject`}</h2>
    <p>{`The `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`referenceObject`}</code>{` is an object that provides an interface compatible with
Popper.js and lets you use it as replacement of a real DOM node.`}<br />{` You can
use this method to position a popper relatively to a set of coordinates in case
you don't have a DOM node to use as reference.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`new`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`Popper`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`referenceObject`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` popperNode`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <p>{`NB: This feature isn't supported in Internet Explorer 10.`}</p>
    <p><strong parentName="p">{`Kind`}</strong>{`: global variable`}<br parentName="p"></br>{`
`}<strong parentName="p">{`Properties`}</strong></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`data.getBoundingClientRect`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><code>{`function`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A function that returns a set of coordinates compatible with the native `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`getBoundingClientRect`}</code>{` method.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`data.clientWidth`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><code>{`number`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`An ES6 getter that will return the width of the virtual reference element.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`data.clientHeight`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><code>{`number`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`An ES6 getter that will return the height of the virtual reference element.`}</td>
        </tr>
      </tbody>
    </table>
    <a name="modifiers"></a>
    <h2 {...{
      "id": "modifiers--codeobjectcode",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#modifiers--codeobjectcode",
        "aria-label": "modifiers  codeobjectcode permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`modifiers : `}<code>{`object`}</code></h2>
    <p>{`Modifiers are plugins used to alter the behavior of your poppers.`}<br />{`
Popper.js uses a set of 9 modifiers to provide all the basic functionalities
needed by the library.`}</p>
    <p>{`Usually you don't want to override the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`order`}</code>{`, `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`fn`}</code>{` and `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`onLoad`}</code>{` props. All the
other properties are configurations that could be tweaked.`}</p>
    <p><strong parentName="p">{`Kind`}</strong>{`: global namespace`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#modifiers"
        }}>{`modifiers`}</a>{` : `}<code>{`object`}</code>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#modifiers..shift"
            }}>{`~shift`}</a>
            <ul parentName="li">
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#modifiers..shift.order"
                }}>{`.order`}</a></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#modifiers..shift.enabled"
                }}>{`.enabled`}</a></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#modifiers..shift.fn"
                }}>{`.fn`}</a></li>
            </ul>
          </li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#modifiers..offset"
            }}>{`~offset`}</a>
            <ul parentName="li">
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#modifiers..offset.order"
                }}>{`.order`}</a></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#modifiers..offset.enabled"
                }}>{`.enabled`}</a></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#modifiers..offset.fn"
                }}>{`.fn`}</a></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#modifiers..offset.offset"
                }}>{`.offset`}</a></li>
            </ul>
          </li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#modifiers..preventOverflow"
            }}>{`~preventOverflow`}</a>
            <ul parentName="li">
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#modifiers..preventOverflow.order"
                }}>{`.order`}</a></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#modifiers..preventOverflow.enabled"
                }}>{`.enabled`}</a></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#modifiers..preventOverflow.fn"
                }}>{`.fn`}</a></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#modifiers..preventOverflow.priority"
                }}>{`.priority`}</a></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#modifiers..preventOverflow.padding"
                }}>{`.padding`}</a></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#modifiers..preventOverflow.boundariesElement"
                }}>{`.boundariesElement`}</a></li>
            </ul>
          </li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#modifiers..keepTogether"
            }}>{`~keepTogether`}</a>
            <ul parentName="li">
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#modifiers..keepTogether.order"
                }}>{`.order`}</a></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#modifiers..keepTogether.enabled"
                }}>{`.enabled`}</a></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#modifiers..keepTogether.fn"
                }}>{`.fn`}</a></li>
            </ul>
          </li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#modifiers..arrow"
            }}>{`~arrow`}</a>
            <ul parentName="li">
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#modifiers..arrow.order"
                }}>{`.order`}</a></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#modifiers..arrow.enabled"
                }}>{`.enabled`}</a></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#modifiers..arrow.fn"
                }}>{`.fn`}</a></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#modifiers..arrow.element"
                }}>{`.element`}</a></li>
            </ul>
          </li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#modifiers..flip"
            }}>{`~flip`}</a>
            <ul parentName="li">
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#modifiers..flip.order"
                }}>{`.order`}</a></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#modifiers..flip.enabled"
                }}>{`.enabled`}</a></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#modifiers..flip.fn"
                }}>{`.fn`}</a></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#modifiers..flip.behavior"
                }}>{`.behavior`}</a></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#modifiers..flip.padding"
                }}>{`.padding`}</a></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#modifiers..flip.boundariesElement"
                }}>{`.boundariesElement`}</a></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#modifiers..flip.flipVariations"
                }}>{`.flipVariations`}</a></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#modifiers..flip.flipVariationsByContent"
                }}>{`.flipVariationsByContent`}</a></li>
            </ul>
          </li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#modifiers..inner"
            }}>{`~inner`}</a>
            <ul parentName="li">
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#modifiers..inner.order"
                }}>{`.order`}</a></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#modifiers..inner.enabled"
                }}>{`.enabled`}</a></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#modifiers..inner.fn"
                }}>{`.fn`}</a></li>
            </ul>
          </li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#modifiers..hide"
            }}>{`~hide`}</a>
            <ul parentName="li">
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#modifiers..hide.order"
                }}>{`.order`}</a></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#modifiers..hide.enabled"
                }}>{`.enabled`}</a></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#modifiers..hide.fn"
                }}>{`.fn`}</a></li>
            </ul>
          </li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#modifiers..computeStyle"
            }}>{`~computeStyle`}</a>
            <ul parentName="li">
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#modifiers..computeStyle.order"
                }}>{`.order`}</a></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#modifiers..computeStyle.enabled"
                }}>{`.enabled`}</a></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#modifiers..computeStyle.fn"
                }}>{`.fn`}</a></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#modifiers..computeStyle.gpuAcceleration"
                }}>{`.gpuAcceleration`}</a></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#modifiers..computeStyle.x"
                }}>{`.x`}</a></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#modifiers..computeStyle.y"
                }}>{`.y`}</a></li>
            </ul>
          </li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#modifiers..applyStyle"
            }}>{`~applyStyle`}</a>
            <ul parentName="li">
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#modifiers..applyStyle.order"
                }}>{`.order`}</a></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#modifiers..applyStyle.enabled"
                }}>{`.enabled`}</a></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#modifiers..applyStyle.fn"
                }}>{`.fn`}</a></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#modifiers..applyStyle.onLoad"
                }}>{`.onLoad`}</a></li>
              <li parentName="ul"><del parentName="li"><a parentName="del" {...{
                    "href": "#modifiers..applyStyle.gpuAcceleration"
                  }}>{`.gpuAcceleration`}</a></del></li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
    <a name="modifiers..shift"></a>
    <h3 {...{
      "id": "modifiersshift",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#modifiersshift",
        "aria-label": "modifiersshift permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`modifiers~shift`}</h3>
    <p>{`Modifier used to shift the popper on the start or end of its reference
element.`}<br />{` It will read the variation of the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`placement`}</code>{` property.`}<br />{` It
can be one either `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`-end`}</code>{` or `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`-start`}</code>{`.`}</p>
    <p><strong parentName="p">{`Kind`}</strong>{`: inner property of `}<a parentName="p" {...{
        "href": "#modifiers"
      }}><code>{`modifiers`}</code></a></p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#modifiers..shift"
        }}>{`~shift`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#modifiers..shift.order"
            }}>{`.order`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#modifiers..shift.enabled"
            }}>{`.enabled`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#modifiers..shift.fn"
            }}>{`.fn`}</a></li>
        </ul>
      </li>
    </ul>
    <a name="modifiers..shift.order"></a>
    <h4 {...{
      "id": "shiftorder",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#shiftorder",
        "aria-label": "shiftorder permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`shift.order`}</h4>
    <p><strong parentName="p">{`Kind`}</strong>{`: static property of `}<a parentName="p" {...{
        "href": "#modifiers..shift"
      }}><code>{`shift`}</code></a><br parentName="p"></br>{`
`}<strong parentName="p">{`Properties`}</strong></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`order`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><code>{`number`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}><code>{`100`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Index used to define the order of execution`}</td>
        </tr>
      </tbody>
    </table>
    <a name="modifiers..shift.enabled"></a>
    <h4 {...{
      "id": "shiftenabled",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#shiftenabled",
        "aria-label": "shiftenabled permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`shift.enabled`}</h4>
    <p><strong parentName="p">{`Kind`}</strong>{`: static property of `}<a parentName="p" {...{
        "href": "#modifiers..shift"
      }}><code>{`shift`}</code></a><br parentName="p"></br>{`
`}<strong parentName="p">{`Properties`}</strong></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`enabled`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><code>{`Boolean`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}><code>{`true`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Whether the modifier is enabled or not`}</td>
        </tr>
      </tbody>
    </table>
    <a name="modifiers..shift.fn"></a>
    <h4 {...{
      "id": "shiftfn",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#shiftfn",
        "aria-label": "shiftfn permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`shift.fn`}</h4>
    <p><strong parentName="p">{`Kind`}</strong>{`: static property of `}<a parentName="p" {...{
        "href": "#modifiers..shift"
      }}><code>{`shift`}</code></a><br parentName="p"></br>{`
`}<strong parentName="p">{`Properties`}</strong></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#ModifierFn"
            }}><code>{`ModifierFn`}</code></a></td>
        </tr>
      </tbody>
    </table>
    <a name="modifiers..offset"></a>
    <h3 {...{
      "id": "modifiersoffset",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#modifiersoffset",
        "aria-label": "modifiersoffset permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`modifiers~offset`}</h3>
    <p>{`The `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`offset`}</code>{` modifier can shift your popper on both its axis.`}</p>
    <p>{`It accepts the following units:`}</p>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`px`}</code>{` or unit-less, interpreted as pixels`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`%`}</code>{` or `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`%r`}</code>{`, percentage relative to the length of the reference element`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`%p`}</code>{`, percentage relative to the length of the popper element`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`vw`}</code>{`, CSS viewport width unit`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`vh`}</code>{`, CSS viewport height unit`}</li>
    </ul>
    <p>{`For length is intended the main axis relative to the placement of the
popper.`}<br />{` This means that if the placement is `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`top`}</code>{` or `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`bottom`}</code>{`, the length
will be the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`width`}</code>{`. In case of `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`left`}</code>{` or `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`right`}</code>{`, it will be the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`height`}</code>{`.`}</p>
    <p>{`You can provide a single value (as `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Number`}</code>{` or `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`String`}</code>{`), or a pair of values as
`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`String`}</code>{` divided by a comma or one (or more) white spaces.`}<br />{` The latter is a
deprecated method because it leads to confusion and will be removed in v2.`}<br />{`
Additionally, it accepts additions and subtractions between different units.
Note that multiplications and divisions aren't supported.`}</p>
    <p>{`Valid examples are:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`10
'10%'
'10, 10'
'10%, 10'
'10 + 10%'
'10 - 5vh + 3%'
'-10px + 5vh, 5px - 6%'`}</code></pre></div>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`NB`}</strong>{`: If you desire to apply offsets to your poppers in a way that may make
them overlap with their reference element, unfortunately, you will have to
disable the `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`flip`}</code>{` modifier. You can read more on this at this
`}<a parentName="p" {...{
          "href": "https://github.com/FezVrasta/popper.js/issues/373"
        }}>{`issue`}</a>{`.`}</p>
    </blockquote>
    <p><strong parentName="p">{`Kind`}</strong>{`: inner property of `}<a parentName="p" {...{
        "href": "#modifiers"
      }}><code>{`modifiers`}</code></a></p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#modifiers..offset"
        }}>{`~offset`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#modifiers..offset.order"
            }}>{`.order`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#modifiers..offset.enabled"
            }}>{`.enabled`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#modifiers..offset.fn"
            }}>{`.fn`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#modifiers..offset.offset"
            }}>{`.offset`}</a></li>
        </ul>
      </li>
    </ul>
    <a name="modifiers..offset.order"></a>
    <h4 {...{
      "id": "offsetorder",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#offsetorder",
        "aria-label": "offsetorder permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`offset.order`}</h4>
    <p><strong parentName="p">{`Kind`}</strong>{`: static property of `}<a parentName="p" {...{
        "href": "#modifiers..offset"
      }}><code>{`offset`}</code></a><br parentName="p"></br>{`
`}<strong parentName="p">{`Properties`}</strong></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`order`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><code>{`number`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}><code>{`200`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Index used to define the order of execution`}</td>
        </tr>
      </tbody>
    </table>
    <a name="modifiers..offset.enabled"></a>
    <h4 {...{
      "id": "offsetenabled",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#offsetenabled",
        "aria-label": "offsetenabled permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`offset.enabled`}</h4>
    <p><strong parentName="p">{`Kind`}</strong>{`: static property of `}<a parentName="p" {...{
        "href": "#modifiers..offset"
      }}><code>{`offset`}</code></a><br parentName="p"></br>{`
`}<strong parentName="p">{`Properties`}</strong></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`enabled`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><code>{`Boolean`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}><code>{`true`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Whether the modifier is enabled or not`}</td>
        </tr>
      </tbody>
    </table>
    <a name="modifiers..offset.fn"></a>
    <h4 {...{
      "id": "offsetfn",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#offsetfn",
        "aria-label": "offsetfn permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`offset.fn`}</h4>
    <p><strong parentName="p">{`Kind`}</strong>{`: static property of `}<a parentName="p" {...{
        "href": "#modifiers..offset"
      }}><code>{`offset`}</code></a><br parentName="p"></br>{`
`}<strong parentName="p">{`Properties`}</strong></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#ModifierFn"
            }}><code>{`ModifierFn`}</code></a></td>
        </tr>
      </tbody>
    </table>
    <a name="modifiers..offset.offset"></a>
    <h4 {...{
      "id": "offsetoffset",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#offsetoffset",
        "aria-label": "offsetoffset permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`offset.offset`}</h4>
    <p><strong parentName="p">{`Kind`}</strong>{`: static property of `}<a parentName="p" {...{
        "href": "#modifiers..offset"
      }}><code>{`offset`}</code></a><br parentName="p"></br>{`
`}<strong parentName="p">{`Properties`}</strong></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`offset`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><code>{`Number`}</code>{` `}{`|`}{` `}<code>{`String`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}><code>{`0`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The offset value as described in the modifier description`}</td>
        </tr>
      </tbody>
    </table>
    <a name="modifiers..preventOverflow"></a>
    <h3 {...{
      "id": "modifierspreventoverflow",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#modifierspreventoverflow",
        "aria-label": "modifierspreventoverflow permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`modifiers~preventOverflow`}</h3>
    <p>{`Modifier used to prevent the popper from being positioned outside the boundary.`}</p>
    <p>{`A scenario exists where the reference itself is not within the boundaries.`}<br />{`
We can say it has "escaped the boundaries" — or just "escaped".`}<br />{` In this
case we need to decide whether the popper should either:`}</p>
    <ul>
      <li parentName="ul">{`detach from the reference and remain "trapped" in the boundaries, or`}</li>
      <li parentName="ul">{`if it should ignore the boundary and "escape with its reference"`}</li>
    </ul>
    <p>{`When `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`escapeWithReference`}</code>{` is set to`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`true`}</code>{` and reference is completely outside
its boundaries, the popper will overflow (or completely leave) the boundaries in
order to remain attached to the edge of the reference.`}</p>
    <p><strong parentName="p">{`Kind`}</strong>{`: inner property of `}<a parentName="p" {...{
        "href": "#modifiers"
      }}><code>{`modifiers`}</code></a></p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#modifiers..preventOverflow"
        }}>{`~preventOverflow`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#modifiers..preventOverflow.order"
            }}>{`.order`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#modifiers..preventOverflow.enabled"
            }}>{`.enabled`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#modifiers..preventOverflow.fn"
            }}>{`.fn`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#modifiers..preventOverflow.priority"
            }}>{`.priority`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#modifiers..preventOverflow.padding"
            }}>{`.padding`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#modifiers..preventOverflow.boundariesElement"
            }}>{`.boundariesElement`}</a></li>
        </ul>
      </li>
    </ul>
    <a name="modifiers..preventOverflow.order"></a>
    <h4 {...{
      "id": "preventoverfloworder",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#preventoverfloworder",
        "aria-label": "preventoverfloworder permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`preventOverflow.order`}</h4>
    <p><strong parentName="p">{`Kind`}</strong>{`: static property of
`}<a parentName="p" {...{
        "href": "#modifiers..preventOverflow"
      }}><code>{`preventOverflow`}</code></a><br parentName="p"></br>{`
`}<strong parentName="p">{`Properties`}</strong></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`order`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><code>{`number`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}><code>{`300`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Index used to define the order of execution`}</td>
        </tr>
      </tbody>
    </table>
    <a name="modifiers..preventOverflow.enabled"></a>
    <h4 {...{
      "id": "preventoverflowenabled",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#preventoverflowenabled",
        "aria-label": "preventoverflowenabled permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`preventOverflow.enabled`}</h4>
    <p><strong parentName="p">{`Kind`}</strong>{`: static property of
`}<a parentName="p" {...{
        "href": "#modifiers..preventOverflow"
      }}><code>{`preventOverflow`}</code></a><br parentName="p"></br>{`
`}<strong parentName="p">{`Properties`}</strong></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`enabled`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><code>{`Boolean`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}><code>{`true`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Whether the modifier is enabled or not`}</td>
        </tr>
      </tbody>
    </table>
    <a name="modifiers..preventOverflow.fn"></a>
    <h4 {...{
      "id": "preventoverflowfn",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#preventoverflowfn",
        "aria-label": "preventoverflowfn permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`preventOverflow.fn`}</h4>
    <p><strong parentName="p">{`Kind`}</strong>{`: static property of
`}<a parentName="p" {...{
        "href": "#modifiers..preventOverflow"
      }}><code>{`preventOverflow`}</code></a><br parentName="p"></br>{`
`}<strong parentName="p">{`Properties`}</strong></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#ModifierFn"
            }}><code>{`ModifierFn`}</code></a></td>
        </tr>
      </tbody>
    </table>
    <a name="modifiers..preventOverflow.priority"></a>
    <h4 {...{
      "id": "preventoverflowpriority",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#preventoverflowpriority",
        "aria-label": "preventoverflowpriority permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`preventOverflow.priority`}</h4>
    <p><strong parentName="p">{`Kind`}</strong>{`: static property of
`}<a parentName="p" {...{
        "href": "#modifiers..preventOverflow"
      }}><code>{`preventOverflow`}</code></a><br parentName="p"></br>{`
`}<strong parentName="p">{`Properties`}</strong></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`priority`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><code>{`Array`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}><code>{`['`}{`left`}{`'`}{`,`}{`'`}{`right`}{`'`}{`,`}{`'`}{`top`}{`'`}{`,`}{`'`}{`bottom`}{`']`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Popper will try to prevent overflow following these priorities by default, then, it could overflow on the left and on top of the `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`boundariesElement`}</code></td>
        </tr>
      </tbody>
    </table>
    <a name="modifiers..preventOverflow.padding"></a>
    <h4 {...{
      "id": "preventoverflowpadding",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#preventoverflowpadding",
        "aria-label": "preventoverflowpadding permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`preventOverflow.padding`}</h4>
    <p><strong parentName="p">{`Kind`}</strong>{`: static property of
`}<a parentName="p" {...{
        "href": "#modifiers..preventOverflow"
      }}><code>{`preventOverflow`}</code></a><br parentName="p"></br>{`
`}<strong parentName="p">{`Properties`}</strong></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`padding`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><code>{`number`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}><code>{`5`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Amount of pixel used to define a minimum distance between the boundaries and the popper. This makes sure the popper always has a little padding between the edges of its container`}</td>
        </tr>
      </tbody>
    </table>
    <a name="modifiers..preventOverflow.boundariesElement"></a>
    <h4 {...{
      "id": "preventoverflowboundarieselement",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#preventoverflowboundarieselement",
        "aria-label": "preventoverflowboundarieselement permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`preventOverflow.boundariesElement`}</h4>
    <p><strong parentName="p">{`Kind`}</strong>{`: static property of
`}<a parentName="p" {...{
        "href": "#modifiers..preventOverflow"
      }}><code>{`preventOverflow`}</code></a><br parentName="p"></br>{`
`}<strong parentName="p">{`Properties`}</strong></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`boundariesElement`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><code>{`String`}</code>{` `}{`|`}{` `}<code>{`HTMLElement`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}><code>{`'`}{`scrollParent`}{`'`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Boundaries used by the modifier. Can be `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`scrollParent`}</code>{`, `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`window`}</code>{`, `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`viewport`}</code>{` or any DOM element.`}</td>
        </tr>
      </tbody>
    </table>
    <a name="modifiers..keepTogether"></a>
    <h3 {...{
      "id": "modifierskeeptogether",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#modifierskeeptogether",
        "aria-label": "modifierskeeptogether permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`modifiers~keepTogether`}</h3>
    <p>{`Modifier used to make sure the reference and its popper stay near each other
without leaving any gap between the two. Especially useful when the arrow is
enabled and you want to ensure that it points to its reference element. It cares
only about the first axis. You can still have poppers with margin between the
popper and its reference element.`}</p>
    <p><strong parentName="p">{`Kind`}</strong>{`: inner property of `}<a parentName="p" {...{
        "href": "#modifiers"
      }}><code>{`modifiers`}</code></a></p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#modifiers..keepTogether"
        }}>{`~keepTogether`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#modifiers..keepTogether.order"
            }}>{`.order`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#modifiers..keepTogether.enabled"
            }}>{`.enabled`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#modifiers..keepTogether.fn"
            }}>{`.fn`}</a></li>
        </ul>
      </li>
    </ul>
    <a name="modifiers..keepTogether.order"></a>
    <h4 {...{
      "id": "keeptogetherorder",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#keeptogetherorder",
        "aria-label": "keeptogetherorder permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`keepTogether.order`}</h4>
    <p><strong parentName="p">{`Kind`}</strong>{`: static property of
`}<a parentName="p" {...{
        "href": "#modifiers..keepTogether"
      }}><code>{`keepTogether`}</code></a><br parentName="p"></br>{`
`}<strong parentName="p">{`Properties`}</strong></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`order`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><code>{`number`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}><code>{`400`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Index used to define the order of execution`}</td>
        </tr>
      </tbody>
    </table>
    <a name="modifiers..keepTogether.enabled"></a>
    <h4 {...{
      "id": "keeptogetherenabled",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#keeptogetherenabled",
        "aria-label": "keeptogetherenabled permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`keepTogether.enabled`}</h4>
    <p><strong parentName="p">{`Kind`}</strong>{`: static property of
`}<a parentName="p" {...{
        "href": "#modifiers..keepTogether"
      }}><code>{`keepTogether`}</code></a><br parentName="p"></br>{`
`}<strong parentName="p">{`Properties`}</strong></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`enabled`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><code>{`Boolean`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}><code>{`true`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Whether the modifier is enabled or not`}</td>
        </tr>
      </tbody>
    </table>
    <a name="modifiers..keepTogether.fn"></a>
    <h4 {...{
      "id": "keeptogetherfn",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#keeptogetherfn",
        "aria-label": "keeptogetherfn permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`keepTogether.fn`}</h4>
    <p><strong parentName="p">{`Kind`}</strong>{`: static property of
`}<a parentName="p" {...{
        "href": "#modifiers..keepTogether"
      }}><code>{`keepTogether`}</code></a><br parentName="p"></br>{`
`}<strong parentName="p">{`Properties`}</strong></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#ModifierFn"
            }}><code>{`ModifierFn`}</code></a></td>
        </tr>
      </tbody>
    </table>
    <a name="modifiers..arrow"></a>
    <h3 {...{
      "id": "modifiersarrow",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#modifiersarrow",
        "aria-label": "modifiersarrow permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`modifiers~arrow`}</h3>
    <p>{`This modifier is used to move the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`arrowElement`}</code>{` of the popper to make sure it
is positioned between the reference element and its popper element. It will read
the outer size of the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`arrowElement`}</code>{` node to detect how many pixels of
conjunction are needed.`}</p>
    <p>{`It has no effect if no `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`arrowElement`}</code>{` is provided.`}</p>
    <p><strong parentName="p">{`Kind`}</strong>{`: inner property of `}<a parentName="p" {...{
        "href": "#modifiers"
      }}><code>{`modifiers`}</code></a></p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#modifiers..arrow"
        }}>{`~arrow`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#modifiers..arrow.order"
            }}>{`.order`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#modifiers..arrow.enabled"
            }}>{`.enabled`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#modifiers..arrow.fn"
            }}>{`.fn`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#modifiers..arrow.element"
            }}>{`.element`}</a></li>
        </ul>
      </li>
    </ul>
    <a name="modifiers..arrow.order"></a>
    <h4 {...{
      "id": "arroworder",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#arroworder",
        "aria-label": "arroworder permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`arrow.order`}</h4>
    <p><strong parentName="p">{`Kind`}</strong>{`: static property of `}<a parentName="p" {...{
        "href": "#modifiers..arrow"
      }}><code>{`arrow`}</code></a><br parentName="p"></br>{`
`}<strong parentName="p">{`Properties`}</strong></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`order`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><code>{`number`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}><code>{`500`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Index used to define the order of execution`}</td>
        </tr>
      </tbody>
    </table>
    <a name="modifiers..arrow.enabled"></a>
    <h4 {...{
      "id": "arrowenabled",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#arrowenabled",
        "aria-label": "arrowenabled permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`arrow.enabled`}</h4>
    <p><strong parentName="p">{`Kind`}</strong>{`: static property of `}<a parentName="p" {...{
        "href": "#modifiers..arrow"
      }}><code>{`arrow`}</code></a><br parentName="p"></br>{`
`}<strong parentName="p">{`Properties`}</strong></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`enabled`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><code>{`Boolean`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}><code>{`true`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Whether the modifier is enabled or not`}</td>
        </tr>
      </tbody>
    </table>
    <a name="modifiers..arrow.fn"></a>
    <h4 {...{
      "id": "arrowfn",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#arrowfn",
        "aria-label": "arrowfn permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`arrow.fn`}</h4>
    <p><strong parentName="p">{`Kind`}</strong>{`: static property of `}<a parentName="p" {...{
        "href": "#modifiers..arrow"
      }}><code>{`arrow`}</code></a><br parentName="p"></br>{`
`}<strong parentName="p">{`Properties`}</strong></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#ModifierFn"
            }}><code>{`ModifierFn`}</code></a></td>
        </tr>
      </tbody>
    </table>
    <a name="modifiers..arrow.element"></a>
    <h4 {...{
      "id": "arrowelement",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#arrowelement",
        "aria-label": "arrowelement permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`arrow.element`}</h4>
    <p><strong parentName="p">{`Kind`}</strong>{`: static property of `}<a parentName="p" {...{
        "href": "#modifiers..arrow"
      }}><code>{`arrow`}</code></a><br parentName="p"></br>{`
`}<strong parentName="p">{`Properties`}</strong></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`element`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><code>{`String`}</code>{` `}{`|`}{` `}<code>{`HTMLElement`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}><code>{`'`}{`[x-arrow]`}{`'`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Selector or node used as arrow`}</td>
        </tr>
      </tbody>
    </table>
    <a name="modifiers..flip"></a>
    <h3 {...{
      "id": "modifiersflip",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#modifiersflip",
        "aria-label": "modifiersflip permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`modifiers~flip`}</h3>
    <p>{`Modifier used to flip the popper's placement when it starts to overlap its
reference element.`}</p>
    <p>{`Requires the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`preventOverflow`}</code>{` modifier before it in order to work.`}</p>
    <p><strong parentName="p">{`NOTE:`}</strong>{` this modifier will interrupt the current update cycle and will restart
it if it detects the need to flip the placement.`}</p>
    <p><strong parentName="p">{`Kind`}</strong>{`: inner property of `}<a parentName="p" {...{
        "href": "#modifiers"
      }}><code>{`modifiers`}</code></a></p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#modifiers..flip"
        }}>{`~flip`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#modifiers..flip.order"
            }}>{`.order`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#modifiers..flip.enabled"
            }}>{`.enabled`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#modifiers..flip.fn"
            }}>{`.fn`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#modifiers..flip.behavior"
            }}>{`.behavior`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#modifiers..flip.padding"
            }}>{`.padding`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#modifiers..flip.boundariesElement"
            }}>{`.boundariesElement`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#modifiers..flip.flipVariations"
            }}>{`.flipVariations`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#modifiers..flip.flipVariationsByContent"
            }}>{`.flipVariationsByContent`}</a></li>
        </ul>
      </li>
    </ul>
    <a name="modifiers..flip.order"></a>
    <h4 {...{
      "id": "fliporder",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#fliporder",
        "aria-label": "fliporder permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`flip.order`}</h4>
    <p><strong parentName="p">{`Kind`}</strong>{`: static property of `}<a parentName="p" {...{
        "href": "#modifiers..flip"
      }}><code>{`flip`}</code></a><br parentName="p"></br>{`
`}<strong parentName="p">{`Properties`}</strong></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`order`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><code>{`number`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}><code>{`600`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Index used to define the order of execution`}</td>
        </tr>
      </tbody>
    </table>
    <a name="modifiers..flip.enabled"></a>
    <h4 {...{
      "id": "flipenabled",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#flipenabled",
        "aria-label": "flipenabled permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`flip.enabled`}</h4>
    <p><strong parentName="p">{`Kind`}</strong>{`: static property of `}<a parentName="p" {...{
        "href": "#modifiers..flip"
      }}><code>{`flip`}</code></a><br parentName="p"></br>{`
`}<strong parentName="p">{`Properties`}</strong></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`enabled`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><code>{`Boolean`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}><code>{`true`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Whether the modifier is enabled or not`}</td>
        </tr>
      </tbody>
    </table>
    <a name="modifiers..flip.fn"></a>
    <h4 {...{
      "id": "flipfn",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#flipfn",
        "aria-label": "flipfn permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`flip.fn`}</h4>
    <p><strong parentName="p">{`Kind`}</strong>{`: static property of `}<a parentName="p" {...{
        "href": "#modifiers..flip"
      }}><code>{`flip`}</code></a><br parentName="p"></br>{`
`}<strong parentName="p">{`Properties`}</strong></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#ModifierFn"
            }}><code>{`ModifierFn`}</code></a></td>
        </tr>
      </tbody>
    </table>
    <a name="modifiers..flip.behavior"></a>
    <h4 {...{
      "id": "flipbehavior",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#flipbehavior",
        "aria-label": "flipbehavior permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`flip.behavior`}</h4>
    <p><strong parentName="p">{`Kind`}</strong>{`: static property of `}<a parentName="p" {...{
        "href": "#modifiers..flip"
      }}><code>{`flip`}</code></a><br parentName="p"></br>{`
`}<strong parentName="p">{`Properties`}</strong></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`behavior`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><code>{`String`}</code>{` `}{`|`}{` `}<code>{`Array`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}><code>{`'`}{`flip`}{`'`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The behavior used to change the popper's placement. It can be one of `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`flip`}</code>{`, `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`clockwise`}</code>{`, `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`counterclockwise`}</code>{` or an array with a list of valid placements (with optional variations)`}</td>
        </tr>
      </tbody>
    </table>
    <a name="modifiers..flip.padding"></a>
    <h4 {...{
      "id": "flippadding",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#flippadding",
        "aria-label": "flippadding permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`flip.padding`}</h4>
    <p><strong parentName="p">{`Kind`}</strong>{`: static property of `}<a parentName="p" {...{
        "href": "#modifiers..flip"
      }}><code>{`flip`}</code></a><br parentName="p"></br>{`
`}<strong parentName="p">{`Properties`}</strong></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`padding`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><code>{`number`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}><code>{`5`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The popper will flip if it hits the edges of the `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`boundariesElement`}</code></td>
        </tr>
      </tbody>
    </table>
    <a name="modifiers..flip.boundariesElement"></a>
    <h4 {...{
      "id": "flipboundarieselement",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#flipboundarieselement",
        "aria-label": "flipboundarieselement permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`flip.boundariesElement`}</h4>
    <p><strong parentName="p">{`Kind`}</strong>{`: static property of `}<a parentName="p" {...{
        "href": "#modifiers..flip"
      }}><code>{`flip`}</code></a><br parentName="p"></br>{`
`}<strong parentName="p">{`Properties`}</strong></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`boundariesElement`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><code>{`String`}</code>{` `}{`|`}{` `}<code>{`HTMLElement`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}><code>{`'`}{`viewport`}{`'`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The element which will define the boundaries of the popper position. The popper will never be placed outside of the defined boundaries (except if `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`keepTogether`}</code>{` is enabled)`}</td>
        </tr>
      </tbody>
    </table>
    <a name="modifiers..flip.flipVariations"></a>
    <h4 {...{
      "id": "flipflipvariations",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#flipflipvariations",
        "aria-label": "flipflipvariations permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`flip.flipVariations`}</h4>
    <p><strong parentName="p">{`Kind`}</strong>{`: static property of `}<a parentName="p" {...{
        "href": "#modifiers..flip"
      }}><code>{`flip`}</code></a><br parentName="p"></br>{`
`}<strong parentName="p">{`Properties`}</strong></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`flipVariations`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><code>{`Boolean`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}><code>{`false`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The popper will switch placement variation between `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`-start`}</code>{` and `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`-end`}</code>{` when the reference element overlaps its boundaries. The original placement should have a set variation.`}</td>
        </tr>
      </tbody>
    </table>
    <a name="modifiers..flip.flipVariationsByContent"></a>
    <h4 {...{
      "id": "flipflipvariationsbycontent",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#flipflipvariationsbycontent",
        "aria-label": "flipflipvariationsbycontent permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`flip.flipVariationsByContent`}</h4>
    <p><strong parentName="p">{`Kind`}</strong>{`: static property of `}<a parentName="p" {...{
        "href": "#modifiers..flip"
      }}><code>{`flip`}</code></a><br parentName="p"></br>{`
`}<strong parentName="p">{`Properties`}</strong></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`flipVariationsByContent`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><code>{`Boolean`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}><code>{`false`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The popper will switch placement variation between `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`-start`}</code>{` and `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`-end`}</code>{` when the popper element overlaps its reference boundaries. The original placement should have a set variation.`}</td>
        </tr>
      </tbody>
    </table>
    <a name="modifiers..inner"></a>
    <h3 {...{
      "id": "modifiersinner",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#modifiersinner",
        "aria-label": "modifiersinner permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`modifiers~inner`}</h3>
    <p>{`Modifier used to make the popper flow toward the inner of the reference element.
By default, when this modifier is disabled, the popper will be placed outside
the reference element.`}</p>
    <p><strong parentName="p">{`Kind`}</strong>{`: inner property of `}<a parentName="p" {...{
        "href": "#modifiers"
      }}><code>{`modifiers`}</code></a></p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#modifiers..inner"
        }}>{`~inner`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#modifiers..inner.order"
            }}>{`.order`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#modifiers..inner.enabled"
            }}>{`.enabled`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#modifiers..inner.fn"
            }}>{`.fn`}</a></li>
        </ul>
      </li>
    </ul>
    <a name="modifiers..inner.order"></a>
    <h4 {...{
      "id": "innerorder",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#innerorder",
        "aria-label": "innerorder permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`inner.order`}</h4>
    <p><strong parentName="p">{`Kind`}</strong>{`: static property of `}<a parentName="p" {...{
        "href": "#modifiers..inner"
      }}><code>{`inner`}</code></a><br parentName="p"></br>{`
`}<strong parentName="p">{`Properties`}</strong></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`order`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><code>{`number`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}><code>{`700`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Index used to define the order of execution`}</td>
        </tr>
      </tbody>
    </table>
    <a name="modifiers..inner.enabled"></a>
    <h4 {...{
      "id": "innerenabled",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#innerenabled",
        "aria-label": "innerenabled permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`inner.enabled`}</h4>
    <p><strong parentName="p">{`Kind`}</strong>{`: static property of `}<a parentName="p" {...{
        "href": "#modifiers..inner"
      }}><code>{`inner`}</code></a><br parentName="p"></br>{`
`}<strong parentName="p">{`Properties`}</strong></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`enabled`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><code>{`Boolean`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}><code>{`false`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Whether the modifier is enabled or not`}</td>
        </tr>
      </tbody>
    </table>
    <a name="modifiers..inner.fn"></a>
    <h4 {...{
      "id": "innerfn",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#innerfn",
        "aria-label": "innerfn permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`inner.fn`}</h4>
    <p><strong parentName="p">{`Kind`}</strong>{`: static property of `}<a parentName="p" {...{
        "href": "#modifiers..inner"
      }}><code>{`inner`}</code></a><br parentName="p"></br>{`
`}<strong parentName="p">{`Properties`}</strong></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#ModifierFn"
            }}><code>{`ModifierFn`}</code></a></td>
        </tr>
      </tbody>
    </table>
    <a name="modifiers..hide"></a>
    <h3 {...{
      "id": "modifiershide",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#modifiershide",
        "aria-label": "modifiershide permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`modifiers~hide`}</h3>
    <p>{`Modifier used to hide the popper when its reference element is outside of the
popper boundaries. It will set a `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`x-out-of-boundaries`}</code>{` attribute which can be
used to hide with a CSS selector the popper when its reference is out of
boundaries.`}</p>
    <p>{`Requires the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`preventOverflow`}</code>{` modifier before it in order to work.`}</p>
    <p><strong parentName="p">{`Kind`}</strong>{`: inner property of `}<a parentName="p" {...{
        "href": "#modifiers"
      }}><code>{`modifiers`}</code></a></p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#modifiers..hide"
        }}>{`~hide`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#modifiers..hide.order"
            }}>{`.order`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#modifiers..hide.enabled"
            }}>{`.enabled`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#modifiers..hide.fn"
            }}>{`.fn`}</a></li>
        </ul>
      </li>
    </ul>
    <a name="modifiers..hide.order"></a>
    <h4 {...{
      "id": "hideorder",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#hideorder",
        "aria-label": "hideorder permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`hide.order`}</h4>
    <p><strong parentName="p">{`Kind`}</strong>{`: static property of `}<a parentName="p" {...{
        "href": "#modifiers..hide"
      }}><code>{`hide`}</code></a><br parentName="p"></br>{`
`}<strong parentName="p">{`Properties`}</strong></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`order`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><code>{`number`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}><code>{`800`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Index used to define the order of execution`}</td>
        </tr>
      </tbody>
    </table>
    <a name="modifiers..hide.enabled"></a>
    <h4 {...{
      "id": "hideenabled",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#hideenabled",
        "aria-label": "hideenabled permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`hide.enabled`}</h4>
    <p><strong parentName="p">{`Kind`}</strong>{`: static property of `}<a parentName="p" {...{
        "href": "#modifiers..hide"
      }}><code>{`hide`}</code></a><br parentName="p"></br>{`
`}<strong parentName="p">{`Properties`}</strong></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`enabled`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><code>{`Boolean`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}><code>{`true`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Whether the modifier is enabled or not`}</td>
        </tr>
      </tbody>
    </table>
    <a name="modifiers..hide.fn"></a>
    <h4 {...{
      "id": "hidefn",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#hidefn",
        "aria-label": "hidefn permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`hide.fn`}</h4>
    <p><strong parentName="p">{`Kind`}</strong>{`: static property of `}<a parentName="p" {...{
        "href": "#modifiers..hide"
      }}><code>{`hide`}</code></a><br parentName="p"></br>{`
`}<strong parentName="p">{`Properties`}</strong></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#ModifierFn"
            }}><code>{`ModifierFn`}</code></a></td>
        </tr>
      </tbody>
    </table>
    <a name="modifiers..computeStyle"></a>
    <h3 {...{
      "id": "modifierscomputestyle",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#modifierscomputestyle",
        "aria-label": "modifierscomputestyle permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`modifiers~computeStyle`}</h3>
    <p>{`Computes the style that will be applied to the popper element to gets properly
positioned.`}</p>
    <p>{`Note that this modifier will not touch the DOM, it just prepares the styles so
that `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`applyStyle`}</code>{` modifier can apply it. This separation is useful in case you
need to replace `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`applyStyle`}</code>{` with a custom implementation.`}</p>
    <p>{`This modifier has `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`850`}</code>{` as `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`order`}</code>{` value to maintain backward compatibility with
previous versions of Popper.js. Expect the modifiers ordering method to change
in future major versions of the library.`}</p>
    <p><strong parentName="p">{`Kind`}</strong>{`: inner property of `}<a parentName="p" {...{
        "href": "#modifiers"
      }}><code>{`modifiers`}</code></a></p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#modifiers..computeStyle"
        }}>{`~computeStyle`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#modifiers..computeStyle.order"
            }}>{`.order`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#modifiers..computeStyle.enabled"
            }}>{`.enabled`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#modifiers..computeStyle.fn"
            }}>{`.fn`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#modifiers..computeStyle.gpuAcceleration"
            }}>{`.gpuAcceleration`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#modifiers..computeStyle.x"
            }}>{`.x`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#modifiers..computeStyle.y"
            }}>{`.y`}</a></li>
        </ul>
      </li>
    </ul>
    <a name="modifiers..computeStyle.order"></a>
    <h4 {...{
      "id": "computestyleorder",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#computestyleorder",
        "aria-label": "computestyleorder permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`computeStyle.order`}</h4>
    <p><strong parentName="p">{`Kind`}</strong>{`: static property of
`}<a parentName="p" {...{
        "href": "#modifiers..computeStyle"
      }}><code>{`computeStyle`}</code></a><br parentName="p"></br>{`
`}<strong parentName="p">{`Properties`}</strong></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`order`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><code>{`number`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}><code>{`850`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Index used to define the order of execution`}</td>
        </tr>
      </tbody>
    </table>
    <a name="modifiers..computeStyle.enabled"></a>
    <h4 {...{
      "id": "computestyleenabled",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#computestyleenabled",
        "aria-label": "computestyleenabled permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`computeStyle.enabled`}</h4>
    <p><strong parentName="p">{`Kind`}</strong>{`: static property of
`}<a parentName="p" {...{
        "href": "#modifiers..computeStyle"
      }}><code>{`computeStyle`}</code></a><br parentName="p"></br>{`
`}<strong parentName="p">{`Properties`}</strong></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`enabled`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><code>{`Boolean`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}><code>{`true`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Whether the modifier is enabled or not`}</td>
        </tr>
      </tbody>
    </table>
    <a name="modifiers..computeStyle.fn"></a>
    <h4 {...{
      "id": "computestylefn",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#computestylefn",
        "aria-label": "computestylefn permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`computeStyle.fn`}</h4>
    <p><strong parentName="p">{`Kind`}</strong>{`: static property of
`}<a parentName="p" {...{
        "href": "#modifiers..computeStyle"
      }}><code>{`computeStyle`}</code></a><br parentName="p"></br>{`
`}<strong parentName="p">{`Properties`}</strong></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#ModifierFn"
            }}><code>{`ModifierFn`}</code></a></td>
        </tr>
      </tbody>
    </table>
    <a name="modifiers..computeStyle.gpuAcceleration"></a>
    <h4 {...{
      "id": "computestylegpuacceleration",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#computestylegpuacceleration",
        "aria-label": "computestylegpuacceleration permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`computeStyle.gpuAcceleration`}</h4>
    <p><strong parentName="p">{`Kind`}</strong>{`: static property of
`}<a parentName="p" {...{
        "href": "#modifiers..computeStyle"
      }}><code>{`computeStyle`}</code></a><br parentName="p"></br>{`
`}<strong parentName="p">{`Properties`}</strong></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`gpuAcceleration`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><code>{`Boolean`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}><code>{`true`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`If true, it uses the CSS 3D transformation to position the popper. Otherwise, it will use the `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`top`}</code>{` and `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`left`}</code>{` properties`}</td>
        </tr>
      </tbody>
    </table>
    <a name="modifiers..computeStyle.x"></a>
    <h4 {...{
      "id": "computestylex",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#computestylex",
        "aria-label": "computestylex permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`computeStyle.x`}</h4>
    <p><strong parentName="p">{`Kind`}</strong>{`: static property of
`}<a parentName="p" {...{
        "href": "#modifiers..computeStyle"
      }}><code>{`computeStyle`}</code></a><br parentName="p"></br>{`
`}<strong parentName="p">{`Properties`}</strong></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`x`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><code>{`string`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}><code>{`"`}{`'`}{`bottom`}{`'`}{`"`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Where to anchor the X axis (`}<code parentName="td" {...{
              "className": "language-text"
            }}>{`bottom`}</code>{` or `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`top`}</code>{`). AKA X offset origin. Change this if your popper should grow in a direction different from `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`bottom`}</code></td>
        </tr>
      </tbody>
    </table>
    <a name="modifiers..computeStyle.y"></a>
    <h4 {...{
      "id": "computestyley",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#computestyley",
        "aria-label": "computestyley permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`computeStyle.y`}</h4>
    <p><strong parentName="p">{`Kind`}</strong>{`: static property of
`}<a parentName="p" {...{
        "href": "#modifiers..computeStyle"
      }}><code>{`computeStyle`}</code></a><br parentName="p"></br>{`
`}<strong parentName="p">{`Properties`}</strong></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`x`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><code>{`string`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}><code>{`"`}{`'`}{`left`}{`'`}{`"`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Where to anchor the Y axis (`}<code parentName="td" {...{
              "className": "language-text"
            }}>{`left`}</code>{` or `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`right`}</code>{`). AKA Y offset origin. Change this if your popper should grow in a direction different from `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`right`}</code></td>
        </tr>
      </tbody>
    </table>
    <a name="modifiers..applyStyle"></a>
    <h3 {...{
      "id": "modifiersapplystyle",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#modifiersapplystyle",
        "aria-label": "modifiersapplystyle permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`modifiers~applyStyle`}</h3>
    <p>{`Applies the computed styles to the popper element.`}</p>
    <p>{`All the DOM manipulations are limited to this modifier. This is useful in case
you want to integrate Popper.js inside a framework or view library and you want
to delegate all the DOM manipulations to it.`}</p>
    <p>{`Note that if you disable this modifier, you must make sure the popper element
has its position set to `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`absolute`}</code>{` before Popper.js can do its work!`}</p>
    <p>{`Just disable this modifier and define your own to achieve the desired effect.`}</p>
    <p><strong parentName="p">{`Kind`}</strong>{`: inner property of `}<a parentName="p" {...{
        "href": "#modifiers"
      }}><code>{`modifiers`}</code></a></p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#modifiers..applyStyle"
        }}>{`~applyStyle`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#modifiers..applyStyle.order"
            }}>{`.order`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#modifiers..applyStyle.enabled"
            }}>{`.enabled`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#modifiers..applyStyle.fn"
            }}>{`.fn`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#modifiers..applyStyle.onLoad"
            }}>{`.onLoad`}</a></li>
          <li parentName="ul"><del parentName="li"><a parentName="del" {...{
                "href": "#modifiers..applyStyle.gpuAcceleration"
              }}>{`.gpuAcceleration`}</a></del></li>
        </ul>
      </li>
    </ul>
    <a name="modifiers..applyStyle.order"></a>
    <h4 {...{
      "id": "applystyleorder",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#applystyleorder",
        "aria-label": "applystyleorder permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`applyStyle.order`}</h4>
    <p><strong parentName="p">{`Kind`}</strong>{`: static property of `}<a parentName="p" {...{
        "href": "#modifiers..applyStyle"
      }}><code>{`applyStyle`}</code></a><br parentName="p"></br>{`
`}<strong parentName="p">{`Properties`}</strong></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`order`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><code>{`number`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}><code>{`900`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Index used to define the order of execution`}</td>
        </tr>
      </tbody>
    </table>
    <a name="modifiers..applyStyle.enabled"></a>
    <h4 {...{
      "id": "applystyleenabled",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#applystyleenabled",
        "aria-label": "applystyleenabled permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`applyStyle.enabled`}</h4>
    <p><strong parentName="p">{`Kind`}</strong>{`: static property of `}<a parentName="p" {...{
        "href": "#modifiers..applyStyle"
      }}><code>{`applyStyle`}</code></a><br parentName="p"></br>{`
`}<strong parentName="p">{`Properties`}</strong></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`enabled`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><code>{`Boolean`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}><code>{`true`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Whether the modifier is enabled or not`}</td>
        </tr>
      </tbody>
    </table>
    <a name="modifiers..applyStyle.fn"></a>
    <h4 {...{
      "id": "applystylefn",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#applystylefn",
        "aria-label": "applystylefn permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`applyStyle.fn`}</h4>
    <p><strong parentName="p">{`Kind`}</strong>{`: static property of `}<a parentName="p" {...{
        "href": "#modifiers..applyStyle"
      }}><code>{`applyStyle`}</code></a><br parentName="p"></br>{`
`}<strong parentName="p">{`Properties`}</strong></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#ModifierFn"
            }}><code>{`ModifierFn`}</code></a></td>
        </tr>
      </tbody>
    </table>
    <a name="modifiers..applyStyle.onLoad"></a>
    <h4 {...{
      "id": "applystyleonload",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#applystyleonload",
        "aria-label": "applystyleonload permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`applyStyle.onLoad`}</h4>
    <p><strong parentName="p">{`Kind`}</strong>{`: static property of `}<a parentName="p" {...{
        "href": "#modifiers..applyStyle"
      }}><code>{`applyStyle`}</code></a><br parentName="p"></br>{`
`}<strong parentName="p">{`Properties`}</strong></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><code>{`function`}</code></td>
        </tr>
      </tbody>
    </table>
    <a name="modifiers..applyStyle.gpuAcceleration"></a>
    <h4 {...{
      "id": "applystylegpuacceleration",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#applystylegpuacceleration",
        "aria-label": "applystylegpuacceleration permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><del parentName="h4">{`applyStyle.gpuAcceleration`}</del></h4>
    <p><strong parentName="p"><em parentName="strong">{`Deprecated`}</em></strong></p>
    <p><strong parentName="p">{`Kind`}</strong>{`: static property of `}<a parentName="p" {...{
        "href": "#modifiers..applyStyle"
      }}><code>{`applyStyle`}</code></a><br parentName="p"></br>{`
`}<strong parentName="p">{`Properties`}</strong></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`gpuAcceleration`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><code>{`Boolean`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}><code>{`true`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`If true, it uses the CSS 3D transformation to position the popper. Otherwise, it will use the `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`top`}</code>{` and `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`left`}</code>{` properties`}</td>
        </tr>
      </tbody>
    </table>
    <a name="getWindow"></a>
    <h2 {...{
      "id": "getwindowelement-⇒-codewindowcode",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#getwindowelement-%E2%87%92-codewindowcode",
        "aria-label": "getwindowelement ⇒ codewindowcode permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`getWindow(element) ⇒ `}<code>{`Window`}</code></h2>
    <p>{`Get the window associated with the element`}</p>
    <p><strong parentName="p">{`Kind`}</strong>{`: global function`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Param`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`element`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><code>{`Element`}</code></td>
        </tr>
      </tbody>
    </table>
    <a name="ModifierFn"></a>
    <h2 {...{
      "id": "modifierfndata-options-⇒-codedataobjectcode",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#modifierfndata-options-%E2%87%92-codedataobjectcode",
        "aria-label": "modifierfndata options ⇒ codedataobjectcode permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`ModifierFn(data, options) ⇒ `}<a parentName="h2" {...{
        "href": "#dataObject"
      }}><code>{`dataObject`}</code></a></h2>
    <p>{`Modifier function, each modifier can have a function of this type assigned to
its `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`fn`}</code>{` property.`}<br />{` These functions will be called on each update, this
means that you must make sure they are performant enough to avoid performance
bottlenecks.`}</p>
    <p><strong parentName="p">{`Kind`}</strong>{`: global function`}<br parentName="p"></br>{`
`}<strong parentName="p">{`Returns`}</strong>{`: `}<a parentName="p" {...{
        "href": "#dataObject"
      }}><code>{`dataObject`}</code></a>{` - The data object, properly
modified`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Param`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`data`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#dataObject"
            }}><code>{`dataObject`}</code></a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The data object generated by `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`update`}</code>{` method`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`options`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><code>{`Object`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Modifiers configuration and options`}</td>
        </tr>
      </tbody>
    </table>
    <a name="onUpdate"></a>
    <h2 {...{
      "id": "onupdate--codefunctioncode",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#onupdate--codefunctioncode",
        "aria-label": "onupdate  codefunctioncode permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`onUpdate : `}<code>{`function`}</code></h2>
    <p><strong parentName="p">{`Kind`}</strong>{`: global typedef`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Param`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`data`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#dataObject"
            }}><code>{`dataObject`}</code></a></td>
        </tr>
      </tbody>
    </table>
    <a name="onCreate"></a>
    <h2 {...{
      "id": "oncreate--codefunctioncode",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#oncreate--codefunctioncode",
        "aria-label": "oncreate  codefunctioncode permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`onCreate : `}<code>{`function`}</code></h2>
    <p><strong parentName="p">{`Kind`}</strong>{`: global typedef`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Param`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`data`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#dataObject"
            }}><code>{`dataObject`}</code></a></td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      